import React, { Component } from 'react';

import ClientStripe from '../../assets/images/circle-stripe.png';

class WhoWeAre extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false,
    };
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section className='about-one '>
        <img src={ClientStripe} className='about-one__circle' alt='' />
        <div className='container text-center'>
          <div className='block-title text-center'>
            <h2 className='block-title__title'>
              Who We Are
              <br />
            </h2>
          </div>
          <p className='about-one__text'>
            Avadhani and Associates is headed by Sri V.S.R.Avadhani, Formerly
            Secretary General, <br />
            Supreme Court of India.
            <br />
            <br />
            Welcome to Avadhani and Associates! Located in the heart of
            Hyderabad, we are dedicated to providing you with a quality legal
            service in an approachable and professional atmosphere. We strive to
            deal with your legal requirements effectively and economically.
            <br /> We are large enough to meet your needs. We are passionate
            about providing you with an exceptional service, <br />
            based on an understanding of your individual requirements and
            priorities. <br />
            We have a panel of best Advocates, Practicing Company Secretaries,
            Chartered Accountants. We maintain utmost confidentiality and
            transparency. Our motto is to provide hassle free and client
            friendly court experience at affordable cost.
            <br /> We provide annual legal maintenance services to companies,
            partnership firms and organizations. <br />
            Our aim is to achieve the best result for you as quickly and cost –
            effectively as possible.
            <br />
            We are here to help you.
          </p>
        </div>
      </section>
    );
  }
}

export default WhoWeAre;
